.alternating-content {
  & .pull {
    order: -1;
  }
}

.section-gradient {
  margin: 8rem 0 6rem;
  position: relative;
  padding: 4rem 0;
  background: rgb(194,216,56);
  background: linear-gradient(121deg, rgba(194,216,56,0.15) 0%, rgba(35,179,175,0.15) 100%);

  @include phone() {
    margin-top: 2rem;
    padding-top: 2rem;
  }
}

.people {

  &-person {

    & .person-avatar {
      width: 100%;
      padding: 0 1rem;

      @include phone() {
        padding: 0 4rem;
      }
    }
    & .person-name {
      margin: 0;
      line-height: 24px;
      text-align: center;
      & a {
        display: flex;
        text-decoration: none;
        color: inherit;
      }
      & img {
        height: 24px;
      }
    }
    & .person-title {
      margin: 0 0 2rem;
      text-align: center;
    }
    & .person-meta {
      margin: 0;
    }
  }
}

.button {
  padding: 8px 1rem;
  background: $deep-blue-500;
  border-radius: 6px;
  color: $neutral-100;
  text-decoration: none;
  @include shadow(#000000, 0.8, 5);
  transition: all 0.25s ease-in-out;

  &:hover, &:focus {
    background: $deep-blue-1000;
    @include shadow(#000000, 0.8, 10);

  }
}

.page_wrapper::before {
  content: "Loading";
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 104px;
  color: $neutral-600;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $neutral-100;
  background-image: url('../svg/casante_mark_c_padded.svg');
  background-repeat: no-repeat;
  background-size: 88px;
  background-position: center center;
  z-index: 1000;
  transition: opacity 150ms ease-in-out;
}

.page_wrapper.loaded::before {
  opacity: 0;
  z-index: 0;
}