
$chartreuse-100: #DFF3A3;
$chartreuse-150: #B4D455;
$chartreuse-200: #9CBD3A;

$pale-teal-100: #CCE6DD;
$pale-teal-150: #A5D7C7;
$pale-teal-180: #84C4B0;

$teal-100: #9DDFDD;
$teal-200: #23B3AF;
$teal-300: #259C9A;

$deep-blue-500: #2B6A80;
$deep-blue-1000: #0C4668;
$deep-blue-1200: #012E4D;

$neutral-100: #F4F4F4;
$neutral-120: #E1E1E2;
$neutral-150: #C8C9CA;
$neutral-200: #ADADAF;
$neutral-400: #7A7C7E;
$neutral-600: #535458;
$neutral-1200: #2A2B30;

$text-primary: $neutral-1200;
$text-secondary: $neutral-600;

$sm-width:  360px;
$md-width:  768px;
$lg-width:  1200px;
$xl-width:  1440px;
$hd-width:  1920px;