//noinspection SassScssResolvedByNameOnly
.footer {
  background: $deep-blue-1200;
  color: $neutral-100;
  &Main {
    padding: 3rem 0 2rem;
    max-width: 1200px;
    margin: 0 auto;
    @include phone() {
      text-align: center;
      padding: 1rem;
    }
    &-brand {
      & svg {
        height: 48px;
        @include phone() {
          height: 32px;
        }
      }
    }
  }

  //noinspection SassScssResolvedByNameOnly
  &Sub {
    padding: 2rem 0;
    max-width: 1200px;
    margin: 0 auto;
    border-top: 1px solid $deep-blue-500;

    @include phone() {
      padding: 0 0 1rem;
      text-align: center;
      border-top: none;
    }
  }

}