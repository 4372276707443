// font-family: "Inter", sans-serif;

html{
  margin: 0;
  padding: 0;
  font-size: 100%;
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Atkinson Hyperlegible", sans-serif;
  line-height: 1.5rem;
  font-weight: 400;
  font-style: normal;
}

#root {
  width: 100vw;
  overflow-x: hidden;
}

@import "partials/definitions";
@import "partials/mixins";
@import "partials/grid";
@import "partials/typography";
@import "partials/masthead";
@import "partials/hero";
@import "partials/sectionContent";

.app-preview {
  position: absolute;
  top: 50%;
  transform: translateY(-35%);
  filter: drop-shadow(10px 10px 10px rgba(0,0,0,0.2)) drop-shadow(20px 20px 20px rgba(0,0,0,0.2));

  @include phone() {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    top: auto;
    filter: none;
    height: 400px;
    width: auto;
    margin: 2rem auto -10rem;
  }
}

@import "partials/footer";
