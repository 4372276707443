.masthead {
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 1200px;
  margin: 0 auto;
  height: auto;
  &-logo {
    height: 48px;

    & svg {
      height: 100%;
    }
  }
}