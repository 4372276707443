.hero {

  &-content {
    padding-right: 4em;
    padding-top: 4rem;
    padding-bottom: 4rem;

    @include phone() {
      padding-top: 15rem;
      padding-bottom: 2rem;
      padding-right: inherit;
    }

    //noinspection SassScssResolvedByNameOnly
    & .title {
      font-family: "Inter", sans-serif;
      color: $text-primary;
      font-weight: 100;
      font-size: 3.5rem;
      line-height: 4rem;
      margin-bottom: 0;
      margin-top: 0;

      @include phone() {
        font-size: 2.5rem;
        font-weight: 200;
        line-height: 3rem;
        text-align: center;
      }
    }

    //noinspection SassScssResolvedByNameOnly
    & .subtitle {
      font-family: "Inter", sans-serif;
      color: $deep-blue-1000;
      font-weight: 500;
      font-size: 1.75rem;
      line-height: 2.5rem;
      margin-top: 1rem;

      @include phone() {
        font-size: 1.5rem;
        line-height: 2rem;
        text-align: center;
      }
    }
  }
  &-image {
    z-index: -1;

    & svg {
      position: absolute;
      top: 50%;
      left: -100px;
      transform: translateY(-30%);

      @include phone() {
        width: 100vw;
        left: 0;
        top: -3rem;
      }
    }
  }
}