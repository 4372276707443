//noinspection SassScssResolvedByNameOnly
.heading-1 {
  font-family: "Inter", sans-serif;
  color: $text-secondary;
  font-weight: 200;
  font-size: 2.5rem;
  line-height: 3.5rem;
  &.mod-center {
    text-align: center;
    font-weight: 200;
  }
  @include phone() {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

//noinspection SassScssResolvedByNameOnly
.heading-2 {
  font-family: "Inter", sans-serif;
  color: $text-secondary;
  font-size: 3.05188rem;
  font-style: normal;
  font-weight: 300;
  line-height: 3.5rem; /* 114.684% */
  &.mod-center {
    text-align: center;
  }

}

.heading-3 {
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 2.5rem;
  line-height: 3.5rem;

}

//noinspection SassScssResolvedByNameOnly
.heading-4 {
  font-family: "Inter", sans-serif;
  color: $deep-blue-1000;
  font-size: 1.5625rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2rem;
  margin-bottom: 8px;

  & + p {
    margin-top: 0;
  }
}

// Margin Rules
.m {
  &-0 {margin:0;}
  &t {
    &-0 {margin-top: 0;}
    &-1 {margin-top: 1rem;}
    &-2 {margin-top: 2rem;}
  }
  &b {
    &-0 {margin-bottom: 0;}
    &-1 {margin-bottom: 1rem;}
    &-2 {margin-bottom: 2rem;}
  }
}

// Padding Rules
.p {
  &t {
    &-1 {padding-top: 1rem;}
    &-2 {padding-top: 2rem;}
  }
  &b {
    &-1 {padding-bottom: 1rem;}
    &-2 {padding-bottom: 2rem;}
  }
}
